import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    selectGetStartedOperationLoading,
    selectGetStartedTmLoading,
    selectStartedOperation,
    selectStartedTm,
} from "./TechMapSelectors";
import { setStartedOperation } from "./TechMapSlice";
import { loadStartedOperation } from "./Thunks";

export const useLoadStartedOperation = () => {
    const loading = useSelector(selectGetStartedTmLoading);
    const loadingOperation = useSelector(selectGetStartedOperationLoading);
    const startedTm = useSelector(selectStartedTm);
    const operation = useSelector(selectStartedOperation);
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        if (startedTm) {
            const startedOperation = startedTm.operations.find(
                (items) => items.status === "in_progress"
            );
            if (startedOperation) {
                dispatch(loadStartedOperation(startedOperation.id));
            }
        }
        return () => {
            dispatch(setStartedOperation(null));
        };
    }, [startedTm?.id]);
    return {
        startedTm,
        operation,
        loading: loadingOperation || loading,
    };
};
