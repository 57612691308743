import { v4 as uuid } from "uuid";

export const getNecessaryItemMock = (
    tmtoUuid: string
): TOperationNecessaryItemCreating => {
    const itemUuid = uuid();
    return {
        tmp_id: itemUuid,
        type: "filling",
        label: "Необходимый элемент",
        on_use_actions: [],
        count: 1,
        technical_map_template_operation_uuid: tmtoUuid,
        related_entity_uuid: null,
        related_entity_row_id: null,
        uuid: itemUuid,
        necessary_type: "components",
        condition_items: [],
    };
};

export const getNecessaryItemUseActionMock = (): TONItemUseActionCreating => {
    const itemUuid = uuid();
    return {
        tmp_id: itemUuid,
        type: "default" as TONItemUseActionType,
        entity_uuid: null,
        entity_field_key: null,
        entity_row_id: null,
        is_calculation: false,
        value: null,
    };
};
export const isSameNecceryItem = (
    firstNecceryItem: TOperationNecessaryItem | TOperationNecessaryItemCreating,
    secondNecceryItem: TOperationNecessaryItem | TOperationNecessaryItemCreating
) => {
    return (
        ("id" in secondNecceryItem &&
            "id" in firstNecceryItem &&
            secondNecceryItem.id === firstNecceryItem.id) ||
        ("tmp_id" in secondNecceryItem &&
            "tmp_id" in firstNecceryItem &&
            secondNecceryItem.tmp_id === firstNecceryItem.tmp_id)
    );
};

export const isCreatedNeccetyItem = (
    items: (TOperationNecessaryItem | TOperationNecessaryItemCreating)[],
    updatedItem: TOperationNecessaryItem | TOperationNecessaryItemCreating
) => {
    return !(
        "tmp_id" in updatedItem &&
        !items.find((item) => "tmp_id" in item && item.tmp_id === updatedItem.tmp_id)
    );
};
