import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

interface IBarItem<T> {
    onClick: (item: T) => void;
    option: TOptionWithItem<T>;
    loading?: boolean;
    active?: boolean;
}

const BarItem = <T,>({ option, loading, active, onClick }: IBarItem<T>) => {
    const onClick_ = () => {
        onClick(option.item);
    };
    return (
        <div
            onClick={onClick_}
            className="bar-item"
            style={{
                backgroundColor: active ? "var(--primary-card)" : undefined,
            }}
        >
            <div className="bar-item__label">{option.label}</div>
            {loading && (
                <Spin
                    size="small"
                    style={{ marginLeft: 10, marginRight: 4 }}
                    indicator={<LoadingOutlined />}
                />
            )}
            {active && <span className="bar-item__active">Активный</span>}
        </div>
    );
};

export default BarItem;
