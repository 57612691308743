import { techMapModel } from "@entities/Production/TechMap";
import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

export const useStartedTMBar = () => {
    const startedTms = useSelector(techMapModel.selectStartedTms);
    const startedTm = useSelector(techMapModel.selectStartedTm);
    const loading = useSelector(techMapModel.selectGetStartedTmsLoading);
    const dispatch = useDispatch<AppDispatch>();
    const activeItemsIds = startedTm?.id ? [startedTm.id] : [];
    const [loadingItemId, setLoadingItemId] = useState<number | null>(null);
    const loadingItemsIds = loadingItemId ? [loadingItemId] : [];
    const options = useMemo(() => {
        return startedTms.map((item) => ({
            value: item.id,
            label: `${item.template?.name} №${item.id}`,
            item,
        }));
    }, [startedTms]);

    const onClickItem = (item: TTechMapStarted) => {
        setLoadingItemId(item.id);
        dispatch(techMapModel.loadStartedTm(item.id)).finally(() => {
            setLoadingItemId(null);
        });
    };

    return { options, loading, activeItemsIds, loadingItemsIds, onClickItem };
};
