import { apiUrl } from "@shared/api/api";
import { PaginatedSelect } from "@shared/ui/PaginatedSelect";
import { EntitySelectCustomReadValue } from "./EntityCustomReadValue";
import { Tooltip } from "antd";
import { useDebounceTooltipOpenEffect } from "@shared/hooks/DebounceTooltipOpenEffect";
import { ReducedDataEffect } from "@shared/ui/ReducedDataEffect";
import { IPaginatedSelect } from "@shared/ui/PaginatedSelect/ui/PaginatedSelect";

interface ISelectEntityListInput {}

interface ISelectEntityListInput {
    value: string;
    onChangeValue: (value: string) => void;
    onlyRead?: boolean;
    additionalParameters: TSelectAdditionalParameters;
    field: TEntityField | TVariable;
    readValueTextStyle: any;
    isReduceValue?: boolean;
}

const SelectEntityListInput = ({
    value,
    onChangeValue,
    additionalParameters,
    onlyRead,
    readValueTextStyle,
    field,
    isReduceValue,
}: ISelectEntityListInput) => {
    const maxValueItemsNumber = 4;
    const { isOpen, handleClick, handleDoubleClick, tooltipInnerStyle } =
        useDebounceTooltipOpenEffect(value?.length, maxValueItemsNumber, isReduceValue);
    const valueKey = field.key_value ?? "id";
    const labelKey = field.key_label ?? "name";
    const isIncludeFilter = additionalParameters?.isIncludeFilter;
    const isCard = additionalParameters?.isCard;

    const baseProps: IPaginatedSelect = {
        readValueTextStyle,
        valueToString: !field.is_multiple,
        multiple: field.is_multiple || isIncludeFilter,
        requestUrl: `${apiUrl}/api/entity/${field.related_entity_uuid}/options?keyValue=${valueKey}&keyLabel=${labelKey}`,
        value,
        params: additionalParameters?.params,
        valueKey,
        onlyRead,
        selectLoading: additionalParameters?.loading,
        labelKey,
        customReadValue: additionalParameters?.entityTableName
            ? (props) => (
                  <EntitySelectCustomReadValue
                      readValueTextStyle={readValueTextStyle}
                      isCard={isCard}
                      renderEntity={additionalParameters.entityTableName}
                      nameField={additionalParameters.nameField}
                      descriptionFields={additionalParameters.descriptionFields}
                      value={props.value}
                      options={props.options}
                  />
              )
            : undefined,
        onChangeValue: onChangeValue,
        initialOptions: additionalParameters?.options ?? [],
    };

    return (
        <Tooltip
            getPopupContainer={(trigger) => trigger.parentElement!}
            autoAdjustOverflow
            overlayStyle={{ maxWidth: 200 }}
            open={
                isOpen &&
                !!isReduceValue &&
                !!value &&
                value?.length > maxValueItemsNumber
            }
            color="white"
            zIndex={900}
            title={
                <div
                    onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                    }}
                >
                    <PaginatedSelect {...baseProps} onlyRead={true} />
                </div>
            }
            overlayInnerStyle={tooltipInnerStyle}
        >
            <div
                onClick={handleClick}
                onDoubleClick={handleDoubleClick}
                style={
                    isReduceValue && !!value && value?.length > maxValueItemsNumber
                        ? {
                              maxHeight: "88px",
                              overflow: "hidden",
                              position: "relative",
                          }
                        : {}
                }
            >
                {isReduceValue && value && value?.length > maxValueItemsNumber && (
                    <ReducedDataEffect />
                )}
                <PaginatedSelect {...baseProps} />
            </div>
        </Tooltip>
    );
};

export default SelectEntityListInput;
