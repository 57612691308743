import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { CardWithAvatar } from "@shared/ui/Cards/CardWithAvatar";
import { Button, Tag } from "antd";
import { MouseEvent } from "react";
import "./TMTONecessaryItems.scss";
import { FlexContainer } from "@shared/ui/Containers/FlexContainer";
import { IndentContainer } from "@shared/ui/Containers/IndentContainer";
interface ITMTONecessaryItem {
    item: TOperationNecessaryItem | TOperationNecessaryItemCreating;
    editingMode?: boolean;
    onEdit: (item: TOperationNecessaryItem | TOperationNecessaryItemCreating) => void;
    onDelete: (item: TOperationNecessaryItem | TOperationNecessaryItemCreating) => void;
}

const TMTONecessaryItem = ({
    item,
    editingMode,
    onEdit,
    onDelete,
}: ITMTONecessaryItem) => {
    const onClickEdit = () => {
        onEdit(item);
    };
    const onClickDelete = (e: MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        onDelete(item);
    };
    return (
        <CardWithAvatar
            hideAvatar
            onClick={editingMode ? onClickEdit : undefined}
            header={
                <FlexContainer type="row-start">
                    <IndentContainer type={["marginRight"]}>{item.label}</IndentContainer>
                    {item.type === "filling" && <Tag color="gold">Учитываемый</Tag>}
                </FlexContainer>
            }
            description={
                <div style={{ display: "flex" }}>
                    <span>Количество:</span>
                    <span style={{ marginLeft: 5 }}>{item.count ?? 1}</span>
                </div>
            }
            actions={
                editingMode && (
                    <div>
                        <Button
                            type="link"
                            danger
                            icon={<DeleteOutlined />}
                            onClick={onClickDelete}
                        />
                        <Button
                            type="link"
                            icon={<EditOutlined />}
                            onClick={onClickEdit}
                        />
                    </div>
                )
            }
        />
    );
};

export default TMTONecessaryItem;
