import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import {
    approveTmThunk,
    attachItemThunk,
    detachItemThunk,
    finishTmThunk,
    loadExecutableTmtsThunk,
    loadTMsThunk,
    nextOperationThunk,
    startTmtThunk,
} from "./TechMapApiThunk";
import { ITechMap } from "./TechMapSlice";

const executableTmtsLoadBuilder = (builder: ActionReducerMapBuilder<ITechMap>) => {
    builder.addCase(loadExecutableTmtsThunk.fulfilled, (state, action) => {
        state.executableTmts = action.payload.data;

        state.getExecutableLoading = false;
    });
    builder.addCase(loadExecutableTmtsThunk.pending, (state, action) => {
        state.getExecutableLoading = true;
    });
    builder.addCase(loadExecutableTmtsThunk.rejected, (state, rejectedValue) => {
        state.getExecutableLoading = false;
        notificationEmit({
            title: "Не удалось загрузить доступные шаблоны",
            type: "error",
        });
    });
};

const startTmtBuilder = (builder: ActionReducerMapBuilder<ITechMap>) => {
    builder.addCase(startTmtThunk.fulfilled, (state, action) => {
        state.startedTm = action.payload.data;
        state.startedTms.unshift(action.payload.data);
        state.startingLoading = null;
    });
    builder.addCase(startTmtThunk.pending, (state, action) => {
        state.startingLoading = action.meta.arg.tmtId;
    });
    builder.addCase(startTmtThunk.rejected, (state, rejectedValue) => {
        state.startingLoading = null;
        console.log("rejectedValue.payload ", rejectedValue.payload);
        const errMessage = (rejectedValue.payload as any)?.data?.data
            ? (Object.values((rejectedValue.payload as any)?.data?.data)?.[0] as
                  | string
                  | undefined)
            : null;
        notificationEmit({
            title: errMessage ?? "Не удалось запустить",
            type: "error",
        });
    });
};
const finishTmBuilder = (builder: ActionReducerMapBuilder<ITechMap>) => {
    builder.addCase(finishTmThunk.fulfilled, (state, action) => {
        state.startedTm = null;
        state.startedOperation = null;
        state.startedTms = state.startedTms.filter(
            (item) => item.id !== action.meta.arg.tmId
        );
        state.nextOperationLoading = false;
        notificationEmit({
            type: "success",
            title: "Процесс успешно завершен!",
            description:
                action.payload.data.status === "waiting"
                    ? "Он будет ожидать одобрения от мастера!"
                    : undefined,
        });
    });
    builder.addCase(finishTmThunk.pending, (state, action) => {
        state.nextOperationLoading = true;
    });
    builder.addCase(finishTmThunk.rejected, (state, rejectedValue) => {
        state.nextOperationLoading = false;
        notificationEmit({
            title: (rejectedValue.payload as any)?.data?.[0] ?? "Не удалось завершить",
            type: "error",
        });
    });
};
const attachItemBuilder = (builder: ActionReducerMapBuilder<ITechMap>) => {
    builder.addCase(attachItemThunk.fulfilled, (state, action) => {
        const newItem = action.payload.data;
        if (state.startedOperation) {
            state.startedOperation.operation_items.push(newItem);
        }
        state.attachItemLoading = false;
    });
    builder.addCase(attachItemThunk.pending, (state, action) => {
        state.attachItemLoading = true;
    });
    builder.addCase(attachItemThunk.rejected, (state, rejectedValue) => {
        state.attachItemLoading = false;
        console.log("rejectedValue.payload", rejectedValue.payload);

        notificationEmit({
            title:
                (rejectedValue.payload as any)?.data?.data?.[0] ??
                "Не удалось прикрепить элемент",
            type: "error",
        });
    });
};
const detachItemBuilder = (builder: ActionReducerMapBuilder<ITechMap>) => {
    builder.addCase(detachItemThunk.fulfilled, (state, action) => {
        if (state.startedOperation) {
            state.startedOperation.operation_items =
                state.startedOperation.operation_items.filter(
                    (oi) => oi.id !== action.payload.data.id
                );
        }
        state.detachItemLoading.filter((item) => item !== action.meta.arg.id);
    });
    builder.addCase(detachItemThunk.pending, (state, action) => {
        state.detachItemLoading.push(action.meta.arg.id);
    });
    builder.addCase(detachItemThunk.rejected, (state, action) => {
        state.detachItemLoading.filter((item) => item !== action.meta.arg.id);

        notificationEmit({
            title:
                (action.payload as any)?.data?.data?.[0] ??
                "Не удалось открепить элемент",
            type: "error",
        });
    });
};
const approveTmBuilder = (builder: ActionReducerMapBuilder<ITechMap>) => {
    builder.addCase(approveTmThunk.fulfilled, (state, action) => {
        const newItem = action.payload.data;
        const level = action.meta.arg.level;
        if (state.techMapsPaginated[level]) {
            state.techMapsPaginated[level].techMaps = (
                state.techMapsPaginated[level].techMaps as any
            ).map((item: any) => (item.id === newItem.id ? newItem : item));
        }

        // state.techMaps = state.techMaps.map((item) =>
        //     item.id === newItem.id ? newItem : item
        // );
        // state.techMapsForReview = state.techMapsForReview.map((item) =>
        //     item.id === newItem.id ? newItem : item
        // );
        delete state.approveTmLoading[action.meta.arg.tmtId];
    });
    builder.addCase(approveTmThunk.pending, (state, action) => {
        state.approveTmLoading[action.meta.arg.tmtId] = action.meta.arg.decision;
    });
    builder.addCase(approveTmThunk.rejected, (state, rejectedValue) => {
        delete state.approveTmLoading[rejectedValue.meta.arg.tmtId];

        notificationEmit({
            title:
                (rejectedValue.payload as any)?.data?.data?.[0] ?? "Не удалось запустить",
            type: "error",
        });
    });
};
const nextOperationBuilder = (builder: ActionReducerMapBuilder<ITechMap>) => {
    builder.addCase(nextOperationThunk.fulfilled, (state, action) => {
        const newOperation = action.payload.data[0];
        if (state.startedTm) {
            state.startedTm = {
                ...state.startedTm,
                operations: [
                    ...state.startedTm.operations.map((operation) => {
                        return operation.id === state.startedOperation?.id
                            ? {
                                  ...operation,
                                  status: "successful" as TTechMapOperationStatus,
                              }
                            : operation;
                    }),
                    newOperation,
                ],
            };
        }
        state.startedOperation = newOperation;

        state.nextOperationLoading = false;
    });
    builder.addCase(nextOperationThunk.pending, (state, action) => {
        state.nextOperationLoading = true;
    });
    builder.addCase(nextOperationThunk.rejected, (state, rejectedValue) => {
        state.nextOperationLoading = false;
        notificationEmit({
            title: (rejectedValue.payload as any)?.data?.[0] ?? "Не удалось запустить",
            type: "error",
        });
    });
};

export const techMapTemplateBuilder = (builder: ActionReducerMapBuilder<ITechMap>) => {
    executableTmtsLoadBuilder(builder);
    startTmtBuilder(builder);
    approveTmBuilder(builder);
    finishTmBuilder(builder);
    attachItemBuilder(builder);
    nextOperationBuilder(builder);
    detachItemBuilder(builder);
};
