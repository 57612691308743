import { entityRowModel } from "@entities/Portal/EntityRow";
import { userModel } from "@entities/Portal/User";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useEntityRowsTableWs = (entityId: number) => {
    const user = useSelector(userModel.selectUser);
    const dispatch = useDispatch();

    useEffect(() => {
        window.Echo.private(`User.${user.id}.EntityRow.${entityId}`)
            .listen(".entityRow.create", ({ entityRow }: { entityRow: any }) => {
                console.log("entityRow", entityRow.id);
                dispatch(
                    entityRowModel.addEntityRowByKey({
                        entityRow,
                        key: 0,
                    })
                );
            })
            .listen(".entityRow.update", ({ entityRow }: { entityRow: any }) => {
                dispatch(
                    entityRowModel.updateEntityRowByKey({
                        entityRow,
                        key: 0,
                    })
                );
            })
            .listen(
                ".entityRow.delete",
                ({
                    entityId,
                    entityRowId,
                }: {
                    entityId: number;
                    entityRowId: number;
                }) => {
                    dispatch(
                        entityRowModel.deleteEntityRowByKey({
                            entityRowId,
                            key: 0,
                        })
                    );
                }
            );

        return () => {
            window.Echo.leave(`User.${user.id}.EntityRow.${entityId}`);
        };
    }, [dispatch, user?.id, entityId]);
};
