import { TMOperations } from "@features/Production/TechMapFeatures/TMOperations";
import { TMTBar } from "@features/Production/TechMapFeatures/TMTBar";
import { StartedTMBar } from "@features/Production/TechMapFeatures/TMTBar/ui";
import { TMOperationProccess } from "@widgets/Production/TMOperationProccess";
import "./ProductionPage.scss";
interface IProductionPage {}

const ProductionPage = ({}: IProductionPage) => {
    return (
        <div className="production-page">
            <div className="production-page__bar">
                <div className="production-page__bar__executable">
                    <TMTBar />
                </div>
                <div className="production-page__bar__started">
                    <StartedTMBar />
                </div>
            </div>
            <div className="production-page__main">
                <div className="production-page__main__operations">
                    <TMOperations />
                </div>
                <TMOperationProccess />
            </div>
        </div>
    );
};

export default ProductionPage;
