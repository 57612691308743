import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectUserRoles, selectUserRolesLoading } from "./CapabilitiesRolesSelectors";
import { setUserRoles } from "./CapabilitiesRolesSlice";
import { getUserRolesThunk } from "./CapabilitiesRolesThunk";
import { parseFormResponse } from "@shared/api/ParseResponse";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";

export const useLoadUserRoles = (userRolesEntityId: number | undefined) => {
    const userRoles = useSelector(selectUserRoles);
    const dispatch = useDispatch<AppDispatch>();
    const [error, setError] = useState<any>(null);
    const loading = useSelector(selectUserRolesLoading);
    useEffect(() => {
        return () => {
            dispatch(setUserRoles([]));
        };
    }, []);

    useEffect(() => {
        if (userRolesEntityId) {
            loadUserRoles(userRolesEntityId);
        }
    }, [userRolesEntityId]);

    const loadUserRoles = (userRolesEntityId: number) => {
        dispatch(getUserRolesThunk(userRolesEntityId))
            .then(parseFormResponse)
            .catch((e: any) => {
                setError(e);
                notificationEmit({
                    type: "error",
                    title: "Ошибка",
                    description: "Ошибка загрузки ролей",
                });
            });
    };

    return {
        userRoles,
        loading,
        error,
    };
};
