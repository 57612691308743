import { parseFormResponse } from "@shared/api/ParseResponse";
import { notificationEmit } from "@shared/ui/NotificationAndMessage";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DEFAULT_PER_PAGE, localStorageCapabilitiesRolesPerPage } from "../constants";
import {
    selectCapabilitiesRoles,
    selectCurrentPage,
    selectInitialCapabilitiesRoles,
    selectLastPage,
    selectCapabilitiesRolesLoading,
} from "./CapabilitiesRolesSelectors";
import {
    setCapabilitiesRoles,
    setCurrentPage,
    setEditingCapabilitiesRolesId,
    setInitialCapabilitiesRoles,
    setLastPage,
} from "./CapabilitiesRolesSlice";
import { loadCapabilitiesRolesThunk } from "./CapabilitiesRolesThunk";

export const useLoadCapabilitiesRoles = (
    role?: TUserRole,
    entityCapabilitiesRolesId?: number
) => {
    const [filters, setFilters] = useState<TFilterValues>([]);
    const capabilitiesRoles = useSelector(selectCapabilitiesRoles);
    const initialCapabilitiesRoles = useSelector(selectInitialCapabilitiesRoles);
    const currentPage = useSelector(selectCurrentPage);
    const lastPage = useSelector(selectLastPage);

    const initialPerPage = Number(
        localStorage.getItem(localStorageCapabilitiesRolesPerPage) ?? DEFAULT_PER_PAGE
    );
    const [perPage, setPerPage] = useState(initialPerPage);
    const dispatch = useDispatch<AppDispatch>();
    const loading = useSelector(selectCapabilitiesRolesLoading);

    useEffect(() => {
        return () => {
            dispatch(setCapabilitiesRoles([]));
            dispatch(setInitialCapabilitiesRoles({}));
            dispatch(setEditingCapabilitiesRolesId([]));
            dispatch(setLastPage(1));
            dispatch(setCurrentPage(1));
        };
    }, []);

    useEffect(() => {
        if (role && entityCapabilitiesRolesId)
            loadCapabilitiesRoles(
                entityCapabilitiesRolesId,
                currentPage,
                perPage,
                filters
            );
    }, [role, currentPage, perPage, filters]);

    const loadCapabilitiesRoles = (
        id: number,
        page: number,
        perPage: number,
        filters: TFilterValues
    ) => {
        const parameters: TMultipleCondition = {
            condition_type: "AND",
            conditions: [
                {
                    key: "role_id",
                    value: role!.id,
                    condition: "=",
                },
                ...(filters!.length > 0
                    ? [
                          {
                              condition_type: "OR",
                              conditions: filters,
                          },
                      ]
                    : []),
            ],
        };

        dispatch(
            loadCapabilitiesRolesThunk({
                id,
                page,
                perPage,
                filter: parameters,
            })
        )
            .then(parseFormResponse)
            .catch((e: any) => {
                console.log(e);
                notificationEmit({ type: "error", title: "Ошибка загрузки прав" });
            });
    };

    const onChangePerPage = (perPage: number) => {
        localStorage.setItem(localStorageCapabilitiesRolesPerPage, perPage.toString());
        setPerPage(perPage);
    };

    const onShowSizeChange = (current: number, size: number) => {
        dispatch(setCurrentPage(current));
        onChangePerPage(size);
    };

    return {
        currentPage,
        perPage,
        filters,
        lastPage,
        capabilitiesRoles,
        initialCapabilitiesRoles,
        loading,
        onShowSizeChange,
        setFilters,
    };
};
