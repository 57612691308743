import { techMapModel } from "@entities/Production/TechMap";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

export const useTMTBar = () => {
    const { executableTmts, loading } = techMapModel.useLoadExecutableTmts();
    const startingLoading = useSelector(techMapModel.selectStartingLoading);
    const startedTm = useSelector(techMapModel.selectStartedTm);
    const dispatch = useDispatch<AppDispatch>();
    const startingLoadingItemsIds = startingLoading ? [startingLoading] : [];
    const activeItemsIds = startedTm?.template?.id ? [startedTm.template.id] : [];

    const options = useMemo(() => {
        return executableTmts.map((item) => ({
            value: item.id,
            label: item.name,
            item,
        }));
    }, [executableTmts]);

    const onClickItem = (item: TTechMapTemplateExecuble) => {
        if (startingLoading) return;
        dispatch(techMapModel.startTmtThunk({ tmtId: item.id }));
    };

    return { options, loading, startingLoadingItemsIds, activeItemsIds, onClickItem };
};
