import { techMapModel } from "@entities/Production/TechMap";
import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";

interface ITMTableApprove {
    techMap: TTechMapStarted;
}

const TMTableApprove = ({ techMap }: ITMTableApprove) => {
    const approveLoading = useSelector((state: RootState) =>
        techMapModel.selectIsApproveLoading(state, techMap.id)
    );
    const rejectLoading = useSelector((state: RootState) =>
        techMapModel.selectIsRejectLoading(state, techMap.id)
    );
    const dispatch = useDispatch<AppDispatch>();

    const onApprove = () => {
        dispatch(
            techMapModel.approveTmThunk({
                tmtId: techMap.id,
                decision: "approve",
            })
        );
    };

    return (
        <Button
            loading={approveLoading}
            disabled={rejectLoading}
            style={{ margin: 0, padding: 0, minHeight: 10 }}
            type="link"
            size="small"
            onClick={onApprove}
        >
            Согласовать
        </Button>
    );
};

export default TMTableApprove;
