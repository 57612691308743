import { FormItem } from "@shared/ui/Form";
import { Button, Input, Select, Space } from "antd";
import {
    tmtoNecessaryItemTypesOptions,
    tmtoNItemNecessaryTypesOptions,
} from "../../constants";
import { EntitySelect } from "@entities/Portal/Entity";
import { SelectEntityRow } from "@entities/Portal/EntityRow";
import { ChangeEvent, useState } from "react";
import { useTMTONecessaryItemForm } from "../../model";
import { TMTONItemUseActions } from "../TMTONItemUseActions";

interface ITMTONecessaryItemForm {
    item: TOperationNecessaryItem | TOperationNecessaryItemCreating;

    onCancel: () => void;
    onSubmit: (item: TOperationNecessaryItem | TOperationNecessaryItemCreating) => void;
}

const TMTONecessaryItemForm = ({ item, onCancel, onSubmit }: ITMTONecessaryItemForm) => {
    const {
        formItem,
        onChangeLabel,
        onChangeCount,
        onChangeType,
        onChangeNecessaryType,
        onChangeEntity,
        onChangeEntityRowId,
        onClickSubmit,
        onChangeUseActions,
    } = useTMTONecessaryItemForm(item, onSubmit);
    return (
        <div
            style={{
                width: "100%",
            }}
        >
            <div
                style={{
                    display: "flex",
                    width: "100%",
                    marginBottom: 15,
                }}
            >
                <div
                    style={{
                        width: "100%",
                        marginRight: 15,
                    }}
                >
                    <FormItem label={"Название"}>
                        <Input value={formItem.label} onChange={onChangeLabel} />
                    </FormItem>

                    <FormItem label={"Тип"}>
                        <Select
                            onChange={onChangeType}
                            style={{ width: "100%" }}
                            value={formItem.type}
                            options={tmtoNecessaryItemTypesOptions}
                        />
                    </FormItem>
                    <FormItem label={"Необходимое количество"}>
                        <Input value={formItem.count} onChange={onChangeCount} />
                    </FormItem>
                    {formItem.type === "filling" ? (
                        <>
                            <FormItem label={"Необходимый компонент"}>
                                <EntitySelect
                                    value={formItem.related_entity_uuid}
                                    onChangeSelect={onChangeEntity}
                                />
                            </FormItem>
                            <FormItem label={"Конкретный компонент"}>
                                {formItem.related_entity_uuid ? (
                                    <SelectEntityRow
                                        labelKey="name"
                                        byUuid
                                        entityInfo={formItem.related_entity_uuid}
                                        value={formItem.related_entity_row_id}
                                        onChange={onChangeEntityRowId}
                                    />
                                ) : (
                                    <>Необходимо выбрать сущность</>
                                )}
                            </FormItem>
                            <FormItem label={"Тип необходимого компонента"}>
                                <Select
                                    onChange={onChangeNecessaryType}
                                    style={{ width: "100%" }}
                                    value={formItem.necessary_type}
                                    options={tmtoNItemNecessaryTypesOptions}
                                />
                            </FormItem>
                        </>
                    ) : (
                        <></>
                    )}
                </div>
                {/* <div
                    style={{
                        width: "100%",
                        padding: 10,
                        maxWidth: 385,
                        borderRadius: 9,
                        border: "1px solid #ededed",
                    }}
                >
                    <TMTONItemUseActions
                        onChange={onChangeUseActions}
                        actions={formItem.on_use_actions}
                    />
                </div> */}
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Space>
                    <Button onClick={onCancel}>Отмена</Button>
                    <Button onClick={onClickSubmit} type="primary">
                        Сохранить
                    </Button>
                </Space>
            </div>
        </div>
    );
};

export default TMTONecessaryItemForm;
