import { Skeleton } from "antd";
import TMOperationItem from "./TMOperationItem";
import "./TMOperationsList.scss";

interface ITMOperationsList {
    templateOperationsWithMapOperation: {
        operation: TTechMapOperation | undefined;
        templateOperation: TTechMapTemplateOperation;
    }[];
    loading?: boolean;
    small?: boolean;
}

const TMOperationsList = ({
    templateOperationsWithMapOperation,
    loading,
    small,
}: ITMOperationsList) => {
    return (
        <div className="tm-operations-list__items">
            {loading ? (
                <Skeleton
                    active
                    rootClassName="tm-operation-skeleton"
                    title={false}
                    paragraph={{ rows: 1, width: ["100%"] }}
                />
            ) : (
                templateOperationsWithMapOperation.map((operationPair) => {
                    return (
                        <TMOperationItem
                            key={operationPair.templateOperation.id}
                            small={small}
                            templateOperation={operationPair.templateOperation}
                            operation={operationPair.operation}
                        />
                    );
                })
            )}
        </div>
    );
};

export default TMOperationsList;
