import { PlusOutlined } from "@ant-design/icons";
import { ERP_LOCATION_PATTERN } from "@shared/constants";
import { Button, Flex, Typography } from "antd";
import "./UserRolesHeader.scss";
interface IUserRolesHeader {
    openDrawer(data: TDrawerManagerItem): void;
}

const UserRolesHeader = ({ openDrawer }: IUserRolesHeader) => {
    return (
        <Flex className="user-roles-header" justify="space-between" align="center">
            <Typography.Title className="user-roles-header__title" level={5}>
                Выбор роли
            </Typography.Title>
            <Button
                type="link"
                className="user-roles-header__create-button"
                icon={<PlusOutlined />}
                onClick={() => {
                    openDrawer({
                        name: "roles",
                        id: null,
                        pattern: ERP_LOCATION_PATTERN,
                    });
                }}
            >
                Создать
            </Button>
        </Flex>
    );
};

export default UserRolesHeader;
