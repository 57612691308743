import {
    BlockTypeSelect,
    BoldItalicUnderlineToggles,
    CreateLink,
    InsertAdmonition,
    InsertTable,
    InsertThematicBreak,
    ListsToggle,
    MDXEditor,
    UndoRedo,
    toolbarPlugin,
} from "@mdxeditor/editor";
import "@mdxeditor/editor/style.css";
import { ReducedDataEffect } from "@shared/ui/ReducedDataEffect";
import { Input } from "antd";
import i18next from "i18next";
import { styled } from "styled-components";
import { plugins } from "./constants";
import "./EditorMarkdownInput.scss";
import { locales } from "./EditorMarkdownLocales";
import { getTranslations } from "./lib";
import { useEditorMarkdownInput } from "./model";

i18next.init({
    lng: "ru",
    debug: true,
    resources: {
        ru: {
            translation: locales,
        },
    },
});

interface IEditorMarkdownInput {
    value: string;
    onlyRead?: boolean;
    additionalParameters?: TLongTextAdditionalParameters;
    onChangeValue: (value: string) => void;
    disabled?: boolean;
    isReduceValue?: boolean;
}

const getToolbarContents = (onlyRead?: boolean) => ({
    toolbarContents: () => {
        if (onlyRead) {
            return <></>;
        }
        return (
            <>
                <UndoRedo />
                <BlockTypeSelect />
                <CreateLink />
                <BoldItalicUnderlineToggles />
                <ListsToggle />
                <InsertTable />
                <InsertAdmonition />
                <InsertThematicBreak />
            </>
        );
    },
});

const MyMDXEditor = styled(MDXEditor)`
    .mdxeditor-toolbar {
        display: ${(props: any) => props.theme.display};
    }
`;

const MyMDXEditorContainer = styled.div`
    height: ${(props: any) => props.theme.height};
    max-height: ${(props: any) => {
        return props.theme.maxHeight;
    }};
    overflow: ${(props: any) => props.theme.overflow};
    position: ${(props: any) => props.theme.relative};
`;

const EditorMarkdownInput = ({
    value,
    onlyRead,
    additionalParameters,
    onChangeValue,
    disabled,
    isReduceValue,
}: IEditorMarkdownInput) => {
    const { ref, onChange, maxValueLength } = useEditorMarkdownInput(
        value,
        onChangeValue
    );

    if (additionalParameters?.isInFilterInput)
        return (
            <Input
                disabled={onlyRead || disabled}
                onChange={onChange}
                value={value ?? ""}
            />
        );
    return (
        <MyMDXEditorContainer
            theme={
                isReduceValue && !!value && value.length > maxValueLength
                    ? {
                          maxHeight: "88px",
                          overflow: "hidden",
                          position: "relative",
                      }
                    : {}
            }
            className={onlyRead ? undefined : "mdx-editor-container"}
        >
            {isReduceValue && !!value && value.length > maxValueLength && (
                <ReducedDataEffect />
            )}
            <MyMDXEditor
                theme={{ display: onlyRead ? "none" : "" }}
                translation={getTranslations}
                ref={ref}
                contentEditableClassName={`mdx-editor-container__editor${onlyRead ? "--onlyread" : ""}`}
                readOnly={onlyRead}
                markdown={value ?? ""}
                plugins={[...plugins, toolbarPlugin(getToolbarContents(onlyRead))]}
                onChange={onChangeValue}
            />
        </MyMDXEditorContainer>
    );
};

export default EditorMarkdownInput;
