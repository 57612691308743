import {
    DeleteOutlined,
    EditOutlined,
    LeftOutlined,
    RightOutlined,
} from "@ant-design/icons";
import { ERP_LOCATION_PATTERN } from "@shared/constants";
import { DragAndDropList } from "@shared/ui/DragAndDropList";
import { openDrawer } from "@shared/ui/DrawerManager";
import { TListItemAddonNodes } from "@shared/ui/List";
import { Button, Flex, Popconfirm, Result, Spin } from "antd";
import { motion } from "framer-motion";
import { isNumber } from "lodash";
import { sidebar } from "../constants";
import DraggableUserRoleListCard from "./DraggableUserRoleListCard";
import { useUserRoles } from "../model";
import "./UserRoles.scss";
import UserRolesHeader from "./UserRolesHeader";

interface IUserRoles {
    userRolesEntityId: number | undefined;
    setRole: (item: TUserRole | undefined) => void;
}

const MotionButton = motion(Button);

const UserRoles = ({ userRolesEntityId, setRole }: IUserRoles) => {
    const {
        setIsDragging,
        userRolesListRef,
        error,
        isOpenSidebar,
        setIsOpenSidebar,
        userRoles,
        loading,
        onDelete,
        onClickUserRole,
        onDragEndUserRoleFunction,
    } = useUserRoles(userRolesEntityId, setRole);

    const actions = (item: TUserRole): TListItemAddonNodes => [
        {
            type: "edit",
            node: (
                <Button
                    type="link"
                    style={{ margin: 0, padding: 0 }}
                    icon={<EditOutlined />}
                />
            ),
            handler: (item) => {
                openDrawer({
                    name: "roles",
                    id: item.id,
                    pattern: ERP_LOCATION_PATTERN,
                });
            },
        },
        {
            type: "delete",
            node: (
                <Popconfirm
                    placement="topRight"
                    title="Удалить роль?"
                    okText="Удалить"
                    cancelText="Отмена"
                    onConfirm={() => onDelete(item)}
                >
                    <Button
                        style={{ margin: 0, padding: 0 }}
                        type="link"
                        danger
                        icon={<DeleteOutlined />}
                    />
                </Popconfirm>
            ),
            handler: () => {},
        },
    ];

    return (
        <Flex vertical className="user-roles" ref={userRolesListRef}>
            <motion.nav
                className="user-roles__nav"
                animate={isOpenSidebar ? "open" : "closed"}
                variants={sidebar}
            >
                <MotionButton
                    className="user-roles__motion-button"
                    icon={isOpenSidebar ? <LeftOutlined /> : <RightOutlined />}
                    onClick={(e) => {
                        e.stopPropagation();
                        setIsOpenSidebar(!isOpenSidebar);
                    }}
                />
                <motion.div
                    className="user-roles__list"
                    initial={{ width: 0 }}
                    exit={{ width: 0 }}
                >
                    <UserRolesHeader openDrawer={openDrawer} />
                    <Spin spinning={loading} wrapperClassName="spin-container-user-roles">
                        {error ? (
                            <Result status="error" title="Ошибка загрузки ролей" />
                        ) : (
                            <DragAndDropList<TUserRole>
                                keyName="id"
                                direction="vertical"
                                className="user-roles__list-container"
                                items={userRoles}
                                onChangeItems={onDragEndUserRoleFunction}
                                draggableItem={(
                                    dragHandleProps,
                                    item,
                                    _,
                                    isDragging,
                                    currentIndex,
                                    sourceIndex
                                ) => {
                                    isDragging !== undefined && setIsDragging(isDragging);
                                    return (
                                        <DraggableUserRoleListCard
                                            item={item}
                                            comparableValue={
                                                isNumber(currentIndex)
                                                    ? userRoles[currentIndex].priority
                                                    : null
                                            }
                                            dragHandleProps={dragHandleProps}
                                            itemAddonNodes={actions}
                                            onClickUserRole={onClickUserRole}
                                            isDragging={isDragging}
                                            currentIndex={currentIndex}
                                            sourceIndex={sourceIndex}
                                        />
                                    );
                                }}
                            />
                        )}
                    </Spin>
                </motion.div>
            </motion.nav>
        </Flex>
    );
};

export default UserRoles;
