import { ERP_LOCATION_PATTERN } from "@shared/constants";
import { openDrawer } from "@shared/ui/DrawerManager";

interface IEntityRowItem {
    name: string;
    id: number | undefined;
    table: string;
}

const EntityRowItem = ({ name, id, table }: IEntityRowItem) => {
    const onClick = () => {
        if (id)
            openDrawer({
                id: id,
                name: table,
                pattern: ERP_LOCATION_PATTERN,
            });
    };

    return (
        <div className="link" onClick={onClick}>
            {name}
        </div>
    );
};

export default EntityRowItem;
