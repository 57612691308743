import { Code } from "@shared/ui/Code";
import { Input } from "antd";
import { ChangeEvent } from "react";

interface IQrCodeInput {
    value: any;
    onlyRead?: boolean;
    onChangeValue: (value: any) => void;
    disabled?: boolean;
}

const QrCodeInput = ({ value, onlyRead, onChangeValue, disabled }: IQrCodeInput) => {
    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChangeValue({
            value: event.target.value === "" ? null : event.target.value,
        });
    };
    if (onlyRead) {
        return value?.value ? (
            <Code
                codeType="qrcode"
                bgColor="white"
                color="black"
                value={value?.value}
                size={100}
                showValue
            />
        ) : (
            <>-</>
        );
    }
    return <Input disabled={disabled} value={value?.value} onChange={onChange} />;
};

export default QrCodeInput;
