import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { addUserRole, updateUserRoles, deleteUserRole } from "./CapabilitiesRolesSlice";

export const useUserRolesWS = (user: TUser, userRolesEntityId: number | undefined) => {
    const dispatch = useDispatch();

    useEffect(() => {
        userRolesEntityId &&
            window.Echo.private(`User.${user.id}.EntityRow.${userRolesEntityId}`)
                .listen(".entityRow.create", ({ entityRow }: { entityRow: any }) => {
                    dispatch(addUserRole(entityRow));
                })
                .listen(".entityRow.update", ({ entityRow }: { entityRow: any }) => {
                    dispatch(updateUserRoles(entityRow));
                })
                .listen(
                    ".entityRow.delete",
                    ({ entityRowId }: { entityRowId: number }) => {
                        dispatch(deleteUserRole(entityRowId));
                    }
                );

        return () => {
            window.Echo.leave(`User.${user.id}.EntityRow.${userRolesEntityId}`);
        };
    }, [userRolesEntityId]);
};
