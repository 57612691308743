import {
    capabilitiesRolesConstants,
    capabilitiesRolesModel,
} from "@entities/Portal/CapabilitiesRoles";
import { entityModel } from "@entities/Portal/Entity";
import { Button, Col, Flex, Pagination, Row, Select, Spin, Tag, Typography } from "antd";
import { useSelector } from "react-redux";
import CapabilitiesRolesRow from "./CapabilitiesRolesRow";
import "./CapabilitiesRolesTable.scss";
import { DeleteOutlined } from "@ant-design/icons";

type TEntitiesLabelsWithIds = Record<string, { id: number; name: string }>;
interface ICapabilitiesRolesTable {
    createButton: React.ReactNode;
    entityCapabilitiesRolesId: number | undefined;
    role: TUserRole | undefined;
    footer: React.ReactNode;
}
const CapabilitiesRolesTable = ({
    createButton,
    entityCapabilitiesRolesId,
    role,
    footer,
}: ICapabilitiesRolesTable) => {
    const entities = useSelector(entityModel.selectEntities);
    const entitiesOptions = entities.map((entity) => ({
        label: entity.name,
        value: entity.uuid,
    }));
    const entitiesLabelsWithIds: TEntitiesLabelsWithIds = entities.reduce(
        (acc1, entity) => {
            return {
                ...acc1,
                [entity.uuid]: { name: entity.name, id: entity.id },
            };
        },
        {}
    );

    const {
        currentPage,
        perPage,
        lastPage,
        filters,
        onShowSizeChange,
        capabilitiesRoles,
        initialCapabilitiesRoles,
        loading,
        setFilters,
    } = capabilitiesRolesModel.useLoadCapabilitiesRoles(role, entityCapabilitiesRolesId);
    const onChangeFilters = (values: string[]) => {
        setFilters(
            values.map((value) => ({ key: "entity_uuid", value, condition: "=" }))
        );
    };
    const resetFilters = () => {
        if (filters && filters?.length > 0) setFilters([]);
    };

    return (
        <>
            <Flex vertical className="capabilities-roles-table__header">
                <Flex
                    align="center"
                    justify="space-between"
                    className="capabilities-roles-table__title-container"
                >
                    <Flex align="center">
                        <Typography.Title
                            level={5}
                            className="capabilities-roles-table__title"
                        >
                            {role?.name ?? "Выберите роль"}
                        </Typography.Title>
                        <Select
                            value={filters?.map(({ value }) => value)}
                            removeIcon
                            showSearch
                            filterOption={(input: any, option) => {
                                return option?.label?.includes(input) === true;
                            }}
                            onChange={onChangeFilters}
                            options={entitiesOptions}
                            mode="multiple"
                            maxTagCount={1}
                            style={{
                                width: "fit-content",
                                minWidth: 240,
                                maxWidth: 420,
                                marginLeft: 16,
                            }}
                            placeholder="Выберите сущность"
                        />
                        <Button
                            onClick={resetFilters}
                            icon={<DeleteOutlined />}
                            type="link"
                        />
                    </Flex>
                    <Flex style={{ paddingRight: 8 }}>{createButton}</Flex>
                </Flex>
            </Flex>
            <Spin
                style={{ display: "flex" }}
                spinning={loading}
                wrapperClassName="capabilities-roles-table__content"
            >
                <div className="capabilities-roles-table__content-container">
                    <Row gutter={0} className="capabilities-roles-table__header-labels">
                        <Col span={3} className="capabilities-roles-table__cell">
                            <Tag color="var(--info)">Сущность</Tag>
                        </Col>
                        {capabilitiesRolesConstants.capabilitiesTypes.map(
                            (type, index, array) => (
                                <Col
                                    key={index}
                                    span={index === array.length - 1 ? 2 : 2}
                                    className="capabilities-roles-table__cell"
                                >
                                    <Tag color={type.tagColor}>{type.label}</Tag>
                                </Col>
                            )
                        )}
                        <Col span={1} className="capabilities-roles-table__cell" />
                    </Row>
                    {capabilitiesRoles.map((entityRow: TCapabilitiesRolesRow, index) => {
                        return (
                            <CapabilitiesRolesRow
                                entityCapabilitiesRolesId={entityCapabilitiesRolesId!}
                                key={entityRow.id}
                                entityRow={entityRow}
                                entityOfEntityRow={
                                    entitiesLabelsWithIds[entityRow.entity_uuid!] ??
                                    undefined
                                }
                                initialEntityRow={initialCapabilitiesRoles[entityRow.id!]}
                            />
                        );
                    })}
                </div>
            </Spin>
            <Flex
                justify="end"
                style={{ padding: 8, background: "#fafafa", flex: "0 1 54px" }}
                align="center"
            >
                <Pagination
                    showSizeChanger
                    onChange={onShowSizeChange}
                    total={lastPage * perPage}
                    pageSize={perPage}
                    current={currentPage}
                />
                <Flex style={{ marginLeft: 8 }}>{footer}</Flex>
            </Flex>
        </>
    );
};

export default CapabilitiesRolesTable;
