import { LoadingOutlined } from "@ant-design/icons";
import { techMapModel } from "@entities/Production/TechMap";
import { AsyncThunkAction } from "@reduxjs/toolkit";
import { CardWithAvatar } from "@shared/ui/Cards/CardWithAvatar";
import { Space, Spin } from "antd";
import { useSelector } from "react-redux";
import { Dispatch } from "redux";
import TMNecessaryItemAttach from "./TMNecessaryItemAttach";
import TMNecessaryItemDetach from "./TMNecessaryItemDetach";
import { SelectEntityRow } from "@entities/Portal/EntityRow";

interface ITMNecessaryItem {
    templateItem: TOperationNecessaryItem;
    operationItem?: TTechMapOperationItem;
}

const TMNecessaryItem = ({ templateItem, operationItem }: ITMNecessaryItem) => {
    const loading = useSelector(techMapModel.selectAttachItemLoading);
    const isAdded = !!operationItem;
    const needFill = templateItem.type === "filling";

    return (
        <CardWithAvatar
            customStyle={
                isAdded || !needFill
                    ? {
                          backgroundColor: "#c5efb0",
                          borderColor: "#c5efb0",
                      }
                    : {
                          backgroundColor: "#f5ecafaa",
                          borderColor: "#f5ecaf",
                      }
            }
            hideAvatar
            header={
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Space>
                        <div>
                            <div>{templateItem.label}</div>
                            {operationItem &&
                                operationItem.related_entity_row_id &&
                                templateItem.related_entity_uuid && (
                                    <SelectEntityRow
                                        onlyRead
                                        byUuid
                                        value={operationItem.related_entity_row_id}
                                        entityInfo={templateItem.related_entity_uuid}
                                        onChange={() => {}}
                                    />
                                )}
                        </div>
                        {!isAdded && needFill && (
                            <div
                                style={{
                                    fontSize: 12,
                                    color: "var(--primary)",
                                }}
                            >
                                {loading ? (
                                    <div>
                                        <Spin indicator={<LoadingOutlined />} />
                                    </div>
                                ) : (
                                    "Ожидает заполнения"
                                )}
                            </div>
                        )}
                        {isAdded && (
                            <TMNecessaryItemDetach operationItemId={operationItem.id} />
                        )}
                    </Space>
                </div>
            }
            rightNode={!isAdded && needFill && <TMNecessaryItemAttach />}
            description={
                <div style={{ display: "flex" }}>
                    <span>Количество:</span>
                    <span style={{ marginLeft: 5 }}>{templateItem.count ?? 1}</span>
                </div>
            }
        />
    );
};

export default TMNecessaryItem;
function dispatch(
    arg0: AsyncThunkAction<
        TResponse<TTechMapOperationItem>,
        { oId: number; value: string },
        {
            state?: unknown;
            dispatch?: Dispatch;
            extra?: unknown;
            rejectValue?: unknown;
            serializedErrorType?: unknown;
            pendingMeta?: unknown;
            fulfilledMeta?: unknown;
            rejectedMeta?: unknown;
        }
    >
) {
    throw new Error("Function not implemented.");
}
