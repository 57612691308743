import { ChangeEvent, useState } from "react";

export const useTMTONecessaryItemForm = (
    item: TOperationNecessaryItem | TOperationNecessaryItemCreating,

    onSubmit: (item: TOperationNecessaryItem | TOperationNecessaryItemCreating) => void
) => {
    const [formItem, setFormItem] = useState(item);

    const onChangeLabel = (event: ChangeEvent<HTMLInputElement>) => {
        setFormItem({
            ...formItem,
            label: event.target.value,
        });
    };
    const onChangeCount = (event: ChangeEvent<HTMLInputElement>) => {
        const value = Number(event.target.value);
        setFormItem({
            ...formItem,
            count:
                event.target.value === "" || value < 1 ? 1 : value > 1000 ? 1000 : value,
        });
    };
    const onChangeType = (type: string) => {
        setFormItem({
            ...formItem,
            type: type as TOperationNecessaryItemType,
            related_entity_uuid: null,
            related_entity_row_id: null,
        });
    };
    const onChangeNecessaryType = (type: string) => {
        setFormItem({
            ...formItem,
            necessary_type: type as TOperationNecessaryItemNecessaryType,
        });
    };
    const onChangeEntity = (entityUuid: string | null) => {
        setFormItem({
            ...formItem,
            related_entity_uuid: entityUuid,
            related_entity_row_id: null,
        });
    };
    const onChangeEntityRowId = (entityRowId: number | null) => {
        setFormItem({
            ...formItem,
            related_entity_row_id: entityRowId,
        });
    };
    const onChangeUseActions = (
        actions: (TONItemUseAction | TONItemUseActionCreating)[]
    ) => {
        setFormItem({
            ...formItem,
            on_use_actions: actions,
        });
    };
    const onClickSubmit = () => {
        onSubmit(formItem);
    };
    return {
        formItem,
        onChangeLabel,
        onChangeCount,
        onChangeType,
        onChangeNecessaryType,
        onChangeEntity,
        onChangeEntityRowId,
        onChangeUseActions,
        onClickSubmit,
    };
};
