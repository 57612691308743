import { techMapModel } from "@entities/Production/TechMap";
import { useTMQrBarActions } from "@features/Production/TechMapFeatures/TMActions";
import { useSelector } from "react-redux";

export const useTMOperationProccess = () => {
    const { loading, operation } = techMapModel.useLoadStartedOperation();
    const loadingTm = useSelector(techMapModel.selectGetStartedTmLoading);

    useTMQrBarActions();
    return { loading: loadingTm || loading, operation };
};
