import { EntityRowItem } from "@entities/Portal/EntityRow";
import TMTableOperations from "../TableItems/TMTableOperations";
import TMTableStatus from "../TableItems/TMTableStatus";
import TMTableApprove from "../TableItems/TMTableApprove";
import TMTableReject from "../TableItems/TMTableReject";

export const getTableColumns = (canReviewSome: boolean) => {
    const columns = [
        {
            title: "Наименование",
            dataIndex: "name",
            key: "name",
            render: (_: any, record: TTechMapStarted) => {
                return record?.template?.name;
            },
        },
        {
            title: "Исполнитель",
            dataIndex: "user",
            key: "user",
            render: (user: TUser | undefined, record: TTechMapStarted) => {
                if (!user) return <>-</>;

                return <EntityRowItem table="users" name={user.name} id={user.id} />;
            },
        },

        {
            title: "Операция",
            dataIndex: "operation",
            key: "operation",
            render: (_: undefined, record: TTechMapStarted) => {
                return <TMTableOperations tm={record} />;
            },
        },
        {
            title: "Статус",
            dataIndex: "status",
            key: "status",
            render: (status: TTechMapStatus, record: TTechMapStarted) => {
                return <TMTableStatus status={status} />;
            },
        },
    ];
    if (canReviewSome) {
        columns.push({
            title: "Согласовать",
            dataIndex: "approve",
            key: "approve",
            render: (_: undefined, record: TTechMapStarted) => {
                if (record.status !== "waiting") return <></>;
                return <TMTableApprove techMap={record} />;
            },
        });
        columns.push({
            title: "Отклонить",
            dataIndex: "reject",
            key: "reject",
            render: (_: undefined, record: TTechMapStarted) => {
                if (record.status !== "waiting") return <></>;
                return <TMTableReject techMap={record} />;
            },
        });
    }
    console.log("columns", columns);

    return columns;
};
