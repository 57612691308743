import { ControlOutlined } from "@ant-design/icons";
import {
    capabilitiesRolesConstants,
    capabilitiesRolesLib,
    CapabilitiesRolesTypeMultiSelectInput,
} from "@entities/Portal/CapabilitiesRoles";
import { Button, Col, Select } from "antd";
import { useCapabilitiesRolesCell } from "../model";

interface ICapabilitiesRolesCell {
    entityCapabilitiesRolesId: number;
    oldValue: any;
    value: string | number | Record<string, any> | null;
    type: TCapType;
    span?: number;
    entityId: number | undefined;
    entityUuid: string;
    onChange: (key: TCapType, value: any) => void;
    isMultiSelectType: boolean | undefined;
    extraActionOfCapabilityType?: () => void;
}

const CapabilitiesRolesCell = ({
    entityCapabilitiesRolesId,
    oldValue,
    value,
    type,
    span,
    entityId,
    entityUuid,
    onChange,
    isMultiSelectType,
    extraActionOfCapabilityType,
}: ICapabilitiesRolesCell) => {
    const {
        isLoading,
        isFocused,
        entityFieldsOptions,
        onChangeValue,
        onFocus,
        onMouseLeaveCell,
        onMouseEnterCell,
        copyValue,
        pasteValue,
    } = useCapabilitiesRolesCell(
        entityCapabilitiesRolesId,
        type,
        entityId,
        entityUuid,
        onChange,
        isMultiSelectType
    );
    return (
        <>
            <Col
                onMouseLeave={onMouseLeaveCell}
                onMouseEnter={onMouseEnterCell}
                span={span ?? 2}
                className="capabilities-roles-table__cell"
                style={{
                    background: capabilitiesRolesLib.getBackgroundColorOfCell(
                        value,
                        type,
                        oldValue
                    ),
                }}
            >
                {type === "constraints" ? (
                    <Button
                        icon={<ControlOutlined />}
                        onClick={extraActionOfCapabilityType}
                        type="link"
                    />
                ) : isMultiSelectType ? (
                    <CapabilitiesRolesTypeMultiSelectInput
                        emptyValue={type === "hidden_fields" ? "-" : "Все"}
                        maxCount={3}
                        isFocused={isFocused}
                        onFocus={onFocus}
                        additionalOptions={entityFieldsOptions}
                        bordered={false}
                        value={value as string}
                        onChange={onChangeValue}
                        isDefaultOpen={false}
                        isLoading={isLoading}
                        copyValue={copyValue}
                        pasteValue={pasteValue}
                    />
                ) : (
                    <Select
                        dropdownStyle={{ width: "fit-content" }}
                        style={{ width: "100%", textAlign: "center" }}
                        options={capabilitiesRolesConstants.options}
                        bordered={false}
                        value={value}
                        loading={isLoading}
                        onChange={onChangeValue}
                    />
                )}
            </Col>
        </>
    );
};

export default CapabilitiesRolesCell;
