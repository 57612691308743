import { Skeleton } from "antd";
import { IndentContainer } from "../Containers/IndentContainer";
import "./Bar.scss";
import BarItem from "./BarItem";
interface IBar<T> {
    label?: string;
    loading?: boolean;
    options: TOptionWithItem<T>[];
    activeValues?: (number | string)[];
    loadingValues?: (number | string)[];
    onClick: (item: T) => void;
}

const Bar = <T,>({
    label,
    loading,
    options,
    activeValues,
    loadingValues,
    onClick,
}: IBar<T>) => {
    return (
        <div className="bar">
            {label && (
                <div className="bar__header">
                    <span className="bar__header__label">{label}</span>
                </div>
            )}
            <div className="bar__content">
                {loading ? (
                    <div className="bar__content__skeleton">
                        <Skeleton
                            active
                            title={false}
                            paragraph={{ rows: 5, width: "100%" }}
                        />
                    </div>
                ) : (
                    <div className="bar__content__items">
                        {options.map((option) => {
                            const isActive = activeValues?.includes(option.value);
                            const isLoading = loadingValues?.includes(option.value);
                            return (
                                <IndentContainer key={option.value}>
                                    <BarItem
                                        active={isActive}
                                        loading={isLoading}
                                        option={option}
                                        onClick={onClick}
                                    />
                                </IndentContainer>
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Bar;
